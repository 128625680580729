

const moduleFeaturedBuyBox = {
  name: 'moduleFeaturedBuyBox',
  transform: (data) => {
    const { fields } = data

    return {
      title: fields?.title || 'Featured Product',
      productSku: fields.productSku,
      colorSelectionTitle: fields?.colorSelectionTitle || 'Color Select:',
      sizeSelectionTitle: fields?.sizeSelectionTitle || 'Size Select:',
      sizeGuideLabel: fields?.sizeGuideLabel || 'Size & Fit Guide',
      addToCartButtonLabelDefault: fields?.addToCartButtonLabelDefault || 'Add to cart',
      addToCartButtonLabelOutOfStock: fields?.addToCartButtonLabelOutOfStock || 'Out of stock',
      addToCartButtonLabelAdd: fields?.addToCartButtonLabelAdd || 'Added to cart',
      addToCartButtonLabelSize: fields?.addToCartButtonLabelSize || 'Please select a size',
      addToCartButtonLabelColor: fields?.addToCartButtonLabelColor || 'Please select a color',
      sizeValidationErrorMessage: fields?.sizeValidationErrorMessage || '(Select a Size)',
      colorValidationErrorMessage: fields?.sizeValidationErrorMessage || '(Select a Color)'
    }
  },
}

export default moduleFeaturedBuyBox
