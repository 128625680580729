const moduleRelatedArticles = {
    name: "moduleRelatedArticles",
  
    transform: (data) => {
  
      const { fields } = data;
      return {
        backgroundImage: fields.backgroundImage.fields.file.url ? "https:"+fields.backgroundImage.fields.file.url : '',
        eyebrow: fields.eyebrow,
        linkToArticle: fields.linkToArticle,
        headline: fields.headline,
        backgroundImage2: fields.backgroundImage2.fields.file.url ? "https:"+fields.backgroundImage2.fields.file.url : '',
        eyebrow2: fields.eyebrow2,
        linkToArticle2: fields.linkToArticle2,
        headline2: fields.headline2,
        backgroundImage3: fields.backgroundImage3.fields.file.url ? "https:"+fields.backgroundImage3.fields.file.url : '',
        eyebrow3: fields.eyebrow3,
        linkToArticle3: fields.linkToArticle3,
        headline3: fields.headline3,
        generalTitle: fields.generalTitle   
    };
    },
  };
  export default moduleRelatedArticles;
  