const moduleImageGalleryUp = {
  name: 'moduleImageGalleryUp',
  transform: (data) => {
    const { fields, sys } = data

    return {
      layout: fields.layout,
      sectionType: fields.sectionType,
      eyebrow: fields.eyebrow,
      headline: fields.headline,
      marginTopBottom: fields.marginTopbottom,
      insert: fields.insert,
      images: fields.images.map((image) => {
        return {
          title: image.title,
          url: 'https:' + image.fields.file.url,
        }
      }),
    }
  },
}

export default moduleImageGalleryUp
