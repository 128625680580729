const moduleHeroText = {
  name: 'moduleHeroText',

  transform: (data) => {
    const { fields } = data || {}
    return {
      contentBlocks: fields.contentBlocks,
      informationBlocks: fields.informationBlocks,
      spaceBelowContent: fields.spaceBelowContent,
      headline: fields.headline,
      bodyText: fields.bodyText,
      cta: {
        label: fields?.cta?.fields?.label,
        url: fields?.cta?.fields?.url,
        openInNewTab: fields?.cta?.fields?.openInNewTab,
        openVideoPlayer: fields?.cta?.fields?.openVideoPlayer,
        metadata: fields?.cta?.metadata,
        id: fields?.cta?.sys?.id,
        backgroundColor: fields?.cta?.fields?.backgroundColor,
      },
      forArticle: fields.forArticle,
      overlayType: fields.overlayType,
      // deprecated - replaced by cta
      ctaLeftUrl: fields.ctaLeftUrl,
      ctaLeftText: fields.ctaLeftText,
    }
  },
}
export default moduleHeroText
