

const moduleProTextContent = {
  name: 'moduleProTextContent',
  transform: (data) => {
    const { fields } = data

    return {
      title: fields?.title || null,
      bodyText: fields?.bodyText || null,
      featureCopies: fields?.featureCopies && fields?.featureCopies.length > 1 ? fields?.featureCopies.filter(copy => {
        return copy.fields
      })
      .map(copy => {
          return {
            ...copy.fields
          }
      }): []
    }
  },
}

export default moduleProTextContent
