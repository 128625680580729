import { fetchGraphQL } from '@/lib/contentful/api'

const moduleSliders = {
  name: 'moduleSliders',

  transform: (data, preview = false) => {
    const { fields } = data

    return {
      sectionPaddingTop: fields.sectionPaddingTop,
      sliderType: fields.sliderType,
      eyebrow: fields.eyebrow,
      title: fields.title,
      titleItalicEnable: fields.titleItalicEnable,
      contentBlock: fields.slides,
      categoryId: fields.categoryId,
      subtitleCta: {
        label: fields?.subtitleCta?.fields?.label,
        url: fields?.subtitleCta?.fields?.url,
        openInNewTab: fields?.subtitleCta?.fields?.openInNewTab,
        openVideoPlayer: fields?.subtitleCta?.fields?.openVideoPlayer,
        id: fields?.subtitleCta?.sys?.id,
        backgroundColor: fields?.subtitleCta?.fields?.backgroundColor,
      },
      cta: {
        label: fields?.cta?.fields?.label,
        url: fields?.cta?.fields?.url,
        openInNewTab: fields?.cta?.fields?.openInNewTab,
        openVideoPlayer: fields?.cta?.fields?.openVideoPlayer,
        id: fields?.cta?.sys?.id,
        backgroundColor: fields?.cta?.fields?.backgroundColor
      },
      background: fields.background,
      useSearchSpring: fields.useSearchSpring,
      searchSpringProfileTag: fields.searchSpringProfileTag,
      showProgressBar: fields.showProgressBar,
      // deprecated - replaced by cta modules
      subtitle: fields.subtitle,
      subtitleUrl: fields.subtitleUrl,
      buttonTitle: fields.buttonTitle,
      buttonUrl: fields.buttonUrl,
    }
  },
  enablePreview: async (module, preview = false) => {
    let id = module.sys.id
    const entry = await fetchGraphQL(
      `query {
        moduleSliders(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
          sectionPaddingTop
          sliderType
          eyebrow
          title
          titleItalicEnable
          subtitleCta {
            label
            url
            openInNewTab
            openVideoPlayer
            backgroundColor
          }
      
          cta {
            label
            url
            openInNewTab
            openVideoPlayer
            backgroundColor
          }

          categoryId
          slidesCollection{
            items{
              ... on ContentBlock{
                coverImage{
                  url
                  width
                  height            
                }
                eyebrow
                title
                timeToRead
                excerpt
                slug
                sys{
                  id
                }        
              }
            }
          }    
          background
          useSearchSpring
          searchSpringProfileTag
          showProgressBar
          # deprecated - replaced by cta modules
          subtitle
          subtitleUrl
          buttonUrl
          buttonTitle  
      }
    }`,
      preview
    )
    return {
      ...entry?.data?.moduleSliders,
      contentBlock: entry?.data?.moduleSliders?.slidesCollection?.items,
    }
  },
}
export default moduleSliders
