import { fetchGraphQL } from '@/lib/contentful/api';

const moduleHero1upSection = {
  name: "moduleHero1upSection",

  transform: (data) => {
    const { fields } = data;

    return {
      fullHeight: fields?.fullHeight,
      inset: fields?.inset,
      backgroundImage: fields?.backgroundImage?.fields?.file.url ? 'https:'+fields?.backgroundImage?.fields?.file.url : '' ,
      overlayType: fields?.overlayType,
      useParallaxBackground: fields?.useParallaxBackground,
      eyebrow: fields?.eyebrow,
      eyebrowMobile: fields?.eyebrowMobile,
      headline: fields?.headline,
      bodyText: fields?.bodyText,
      contentPosition: fields?.contentPosition,
      ctaLeftText: fields?.ctaLeftText,
      ctaLeftUrl: fields?.ctaLeftUrl,
      ctaRightText: fields?.ctaRightText,
      ctaRightUrl: fields?.ctaRightUrl,
      textContentFullWidth: fields?.textContentFullWidth,
      smallerLetterSpacing: fields.smallerLetterSpacing,
      hiddenBodyTextOnMobile: fields.hiddenBodyTextOnMobile,
      typeHeroEnable: fields.typeHeroEnable,
      sectionType: fields.sectionType,
      contentPositionOnMobile: fields.contentPositionOnMobile,
      spiceBottomEnable: fields.spiceBottomEnable,
      bodyTextSize: fields.bodyTextSize
    }
  },

  enablePreview: async (module, preview = false) => {
    console.log("preview enabled",module);
    let id = module.sys.id;
    const entry = await fetchGraphQL(
      `query {
        moduleHero1UpSection(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
          fullHeight
          inset
          backgroundImage{
            url
          }
          overlayType
          useParallaxBackground
          eyebrow
          eyebrowMobile
          headline
          bodyText{
            json
          }
          contentPosition
          ctaLeftText
          ctaLeftUrl
          ctaRightText
          ctaRightUrl
          textContentFullWidth
          smallerLetterSpacing
          hiddenBodyTextOnMobile
          sectionType
          contentPositionOnMobile
          spiceBottomEnable
          bodyTextSize      
      }
    }`,preview
    );
    console.log("entry data hero 1 -----",entry)
    return entry?.data?.moduleHero1UpSection;
  }
}
export default moduleHero1upSection;