import Adapter from '@/lib/contentful/adapters'
import dynamic from 'next/dynamic'
// import DepoteLandingContent from '@/components/DepoteLanding/DepoteLandingContent'
const DepoteLandingContent = dynamic(
  () => import('@/components/DepoteLanding/DepoteLandingContent').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import HeroDefault from '@/components/Hero/HeroDefault'
const HeroDefault = dynamic(
  () => import('@/components/Hero/HeroDefault').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import HeroPlp from '@/components/Hero/HeroPlp'
const HeroPlp = dynamic(() => import('@/components/Hero/HeroPlp').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})
// import HeroPlpSecond from '@/components/Hero/HeroPlpSecond'
const HeroPlpSecond = dynamic(
  () => import('@/components/Hero/HeroPlpSecond').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ImageGallery1Up from '@/components/Image/ImageGallery1Up'
const ImageGallery1Up = dynamic(
  () => import('@/components/Image/ImageGallery1Up').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ImageGallery1UpSlider from '@/components/Image/ImageGallery1UpSlider'
const ImageGallery1UpSlider = dynamic(
  () => import('@/components/Image/ImageGallery1UpSlider').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ImageGallery2Up from '@/components/Image/ImageGallery2Up'
const ImageGallery2Up = dynamic(
  () => import('@/components/Image/ImageGallery2Up').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ImageGallery3Up from '@/components/Image/ImageGallery3Up'
const ImageGallery3Up = dynamic(
  () => import('@/components/Image/ImageGallery3Up').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ImageGalleryMosaic from '@/components/Image/ImageGalleryMosaic'
const ImageGalleryMosaic = dynamic(
  () => import('@/components/Image/ImageGalleryMosaic').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ImageGalleryUp from '@/components/Image/ImageGalleryUp'
const ImageGalleryUp = dynamic(
  () => import('@/components/Image/ImageGalleryUp').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import Markdown from '../atoms/Markdown'
const Markdown = dynamic(() => import('../atoms/Markdown').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})
// import Module3UpText from '../ModuleContent3Blocks/Module3UpText'
const Module3UpText = dynamic(
  () => import('../ModuleContent3Blocks/Module3UpText').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ModuleAccordionSection from '@/components/ModuleAccordionSection/ModuleAccordionSection'
const ModuleAccordionSection = dynamic(
  () =>
    import('@/components/ModuleAccordionSection/ModuleAccordionSection').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ModuleContent2Blocks from '../ModuleContent2Blocks/ModuleContent2Blocks'
const ModuleContent2Blocks = dynamic(
  () => import('../ModuleContent2Blocks/ModuleContent2Blocks').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ModuleContent2Images from '../ModuleContent2Images/ModuleContent2Images'
const ModuleContent2Images = dynamic(
  () => import('../ModuleContent2Images/ModuleContent2Images').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ModuleContent3Blocks from '../ModuleContent3Blocks/ModuleContent3Blocks'
const ModuleContent3Blocks = dynamic(
  () => import('../ModuleContent3Blocks/ModuleContent3Blocks').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ModuleHero1upSection from '@/components/ModuleHero1upSection/ModuleHero1upSection'
const ModuleHero1upSection = dynamic(
  () => import('@/components/ModuleHero1upSection/ModuleHero1upSection').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ModuleHeroBanner from '../ModuleHeroBanner/ModuleHeroBanner'
const ModuleHeroBanner = dynamic(
  () => import('../ModuleHeroBanner/ModuleHeroBanner').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ModuleHeroSection from '@/components/ModuleHeroSection/ModuleHeroSection'
const ModuleHeroSection = dynamic(
  () => import('@/components/ModuleHeroSection/ModuleHeroSection').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ModuleHeroText from '../Hero/ModuleHeroText'
const ModuleHeroText = dynamic(() => import('../Hero/ModuleHeroText').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})
// import ModuleHeroVideo from '../Hero/ModuleHeroVideo'
const ModuleHeroVideo = dynamic(
  () => import('../Hero/ModuleHeroVideo').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ModuleRelatedArticles from '../ModuleContent3Blocks/ModuleRelatedArticles'
const ModuleRelatedArticles = dynamic(
  () => import('../ModuleContent3Blocks/ModuleRelatedArticles').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ModuleSliders from '@/components/ModuleSliders/ModuleSliders'
const ModuleSliders = dynamic(
  () => import('@/components/ModuleSliders/ModuleSliders').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import PDPControls from '@/components/Product/PDPControls'
const PDPControls = dynamic(
  () => import('@/components/Product/PDPControls').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import PageContent from '../Content/pageContent'
const PageContent = dynamic(() => import('../Content/pageContent').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})
// import PageHeader from '@/components/PageHeader/PageHeader'
const PageHeader = dynamic(
  () => import('@/components/PageHeader/PageHeader').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ProfileModuleFeaturedBuyBox from '@/components/Account/ProfileModuleFeaturedBuyBox'
const ProfileModuleFeaturedBuyBox = dynamic(
  () => import('@/components/Account/ProfileModuleFeaturedBuyBox').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ProfileModuleHero from '@/components/Account/ProfileModuleHero'
const ProfileModuleHero = dynamic(
  () => import('@/components/Account/ProfileModuleHero').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ProfileModuleProTextContent from '@/components/Account/ProfileModuleProTextContent'
const ProfileModuleProTextContent = dynamic(
  () => import('@/components/Account/ProfileModuleProTextContent').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import ProfileModuleProduct from '@/components/Account/ProfileModuleProduct'
const ProfileModuleProduct = dynamic(
  () => import('@/components/Account/ProfileModuleProduct').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)
// import Promo1Up from '@/components/Promo/promo1up'
const Promo1Up = dynamic(() => import('@/components/Promo/promo1up').then((mod) => mod.default), {
  loading: () => null,
  ssr: true,
})
// import Promo1UpVideo from '@/components/Promo/promo1UpVideo'
const Promo1UpVideo = dynamic(
  () => import('@/components/Promo/promo1UpVideo').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)

// import ModuleHero from '@/components/Hero/ModuleHero'
const ModuleHero = dynamic(
  () => import('@/components/Hero/ModuleHero').then((mod) => mod.default),
  {
    loading: () => null,
    ssr: true,
  }
)

const renderData = (
  data,
  contentType,
  module,
  key,
  index,
  product,
  variant,
  categoryName,
  spectRef,
  wrInfoRef,
  preview
) => {
  const moduleherojsx = {
    'Hero / Default - center': (
      <HeroDefault {...data} contentPosition={'center'} sectionType={'center'} />
    ),
    'Hero / Default Inset - center': (
      <HeroDefault {...data} contentPosition={'center'} sectionType={'center'} inset={true} />
    ),
    'Hero / Default - left': <HeroDefault {...data} contentPosition={'left'} />,
    'Hero / Default Inset - left': <HeroDefault {...data} contentPosition={'left'} inset={true} />,
    'Hero / PLP - center': (
      <HeroPlpSecond {...data} contentPosition={'center'} sectionType={'Hero / PLP - center'} />
    ),
    'Hero / PLP - left': <HeroPlpSecond {...data} sectionType={'Hero / PLP - left'} />,
    'Hero / PLP Sort - center': <HeroPlp {...data} contentPosition={'center'} />,
    'Hero / PLP Sort Blank - center': (
      <HeroPlp {...data} contentPosition={'center'} sectionBackground={'blank'} />
    ),
    'Hero / Promo 1 Up - center': (
      <Promo1Up {...data} contentPosition={'center'} wrInfoRef={wrInfoRef} />
    ),
    'Hero / Promo 1 Up Inset - center': (
      <Promo1Up {...data} contentPosition={'center'} inset={true} />
    ),
    'Hero / Promo 1 Up - left': <Promo1Up {...data} contentPosition={'left'} />,
    'Hero / Promo 1 Up Inset - left': <Promo1Up {...data} contentPosition={'left'} inset={true} />,
    'Hero / Promo 1 Up Video Insert': (
      <Promo1UpVideo {...data} bodyText={module.fields?.bodyText} inset={true} />
    ),
    'Hero / Promo 1 Up Video': (
      <Promo1UpVideo {...data} bodyText={module.fields?.bodyText} inset={false} />
    ),
    'Hero / Text content': <DepoteLandingContent {...data} />,
  }

  //please don't remove below object
  const moduleImageGalleryJSX = {
    'Image Gallery 1 Up': <ImageGallery1Up {...data} />,
    'Image Gallery 1 Up - slider': <ImageGallery1UpSlider {...data} />,
    'Image Gallery 2 Up': <ImageGallery2Up {...data} />,
    'Image Gallery 3 Up': <ImageGallery3Up {...data} />,
    'Image Gallery Mosaic': <ImageGalleryMosaic {...data} reverse={module.fields?.mosaicReverse} />,
  }

  const modules = {
    profilemodulehero: <ProfileModuleHero {...data.fields} />,
    profilemoduleproduct: <ProfileModuleProduct {...data.fields} />,
    //modulehero: moduleherojsx[module.fields?.sectionType],
    modulehero: <ModuleHero {...data} />,
    moduleimagegalleryup: <ImageGalleryUp {...data} />, //moduleImageGalleryJSX[module.fields?.sectionType],
    modulepageheader: <PageHeader {...data} />,
    modulepagedescription: <PageContent {...data} />,
    productfeatures: <PDPControls {...data} spectRef={spectRef} />,

    // new modules
    modulecontent2blocks: <ModuleContent2Blocks {...data} />,
    modulecontent2images: <ModuleContent2Images {...data} />,
    modulecontent3blocks: <ModuleContent3Blocks {...data} />,
    modulerelatedarticles: <ModuleRelatedArticles {...data} />,
    modulepromovideo: <ModuleHeroVideo {...data} preview={preview} />,
    moduleherotext: <ModuleHeroText {...data} />,
    moduleglobalbrands: <Module3UpText {...data} />,
    moduleaccordionsection: <ModuleAccordionSection {...data} />,
    modulesliders: <ModuleSliders {...data} product={product} sys={module.sys} />,
    moduleherosection: <ModuleHeroSection {...data} />,
    modulehero1upsection: <ModuleHero1upSection {...data} />,
    moduleherobanner: <ModuleHeroBanner {...data} />,
    // modulemarkdowntext: (
    //   <div className="container prose">
    //     <Markdown>{data?.fields?.markdownText}</Markdown>
    //   </div>
    // ),
    modulemarkdowntext: <Markdown {...data} />,
    moduleprotextcontent: <ProfileModuleProTextContent {...data} />,
    modulefeaturedbuybox: <ProfileModuleFeaturedBuyBox {...data} />,
  }
  if (modules[`${contentType.toLowerCase()}`]) {
    return modules[`${contentType.toLowerCase()}`]
  }
  return null
}

const getModule = (
  contentType,
  module,
  key,
  index,
  product,
  variant,
  categoryName,
  spectRef,
  wrInfoRef,
  preview
) => {
  // if(!preview){
  let data = {
    ...Adapter(String(contentType), module, preview),
    product,
    variant,
    categoryName,
    index,
    key,
    spectRef,
    sys: module.sys,
    preview,
  }
  return renderData(
    data,
    contentType,
    module,
    key,
    index,
    product,
    variant,
    categoryName,
    spectRef,
    wrInfoRef,
    preview
  )
  // please don't remove this code
  // }else{
  //   Adapter(String(contentType), module,preview).then(res=>{
  //   let data = {
  //     ... res,
  //     product,
  //     variant,
  //     categoryName,
  //     index,
  //     key,
  //     spectRef,
  //     sys: module.sys,
  //     preview
  //   };

  //  return renderData(data,contentType, module, key, index, product, variant, categoryName, spectRef, wrInfoRef,preview);

  // })
}
const Modules = ({
  modules,
  product,
  variant,
  categoryName,
  spectRef,
  retailLocator,
  wrInfoRef,
  preview,
}) => {
  // console.log('modules', modules)
  return (
    <div
      className={`modules
    ${
      modules &&
      modules.length > 0 &&
      modules[0]?.fields?.entryTitle !== undefined &&
      modules[0].fields?.entryTitle.toLowerCase().startsWith('grant program')
        ? 'grant-program-page'
        : ''
    }
    ${
      modules &&
      modules.length > 0 &&
      modules[0]?.fields?.entryTitle !== undefined &&
      modules[0].fields?.entryTitle.toLowerCase().startsWith('revive')
        ? 'revive-page'
        : ''
    }
    `}
    >
      {modules != null &&
        modules.map((module, index) => {
          // keep this flow till i'll create preview for all pages
          // let moduleType = preview ? module.__typename : module.sys.contentType.sys.id;
          if (module?.sys?.contentType) {
            return getModule(
              module.sys.contentType.sys.id,
              module,
              module.sys.id + '-' + index,
              index,
              product,
              variant,
              categoryName,
              spectRef,
              wrInfoRef,
              preview,
              index
            )
          }
          if (preview) {
            return getModule(
              module.__typename,
              module,
              module.sys.id + '-' + index,
              index,
              product,
              variant,
              categoryName,
              spectRef,
              wrInfoRef,
              preview
            )
          }
        })}
    </div>
  )
}

export default Modules
