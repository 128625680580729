import { fetchGraphQL } from '@/lib/contentful/api'

const moduleContent2Blocks = {
  name: 'moduleContent2Blocks',

  transform: (data, preview = false) => {
    const { fields } = data

    return {
      inset: fields?.inset,
      contentAboveTitle: fields?.contentAboveTitle,
      overlayType: fields?.overlayType,
      eyebrow: fields?.eyebrow,
      imageSize: fields?.imageSize,
      headline: fields?.headline,
      generalSectionEyebrow: fields?.generalSectionEyebrow,
      generalSectionTitle: fields?.generalSectionTitle,
      generalSectionSubtitle: fields?.generalSectionSubtitle,
      generalPaddingDisable: fields?.generalPaddingDisable,
      imagePosition: fields?.imagePosition,
      bodyText: fields?.bodyText,
      backgroundImage: fields?.backgroundImage?.fields?.file
        ? 'https:' + fields?.backgroundImage?.fields?.file?.url
        : '',
      altTag: fields?.backgroundImage?.fields?.file
        ? fields?.backgroundImage?.fields?.description
        : '',
      primaryCta: {
        label: fields?.primaryCta?.fields?.label,
        url: fields?.primaryCta?.fields?.url,
        openInNewTab: fields?.primaryCta?.fields?.openInNewTab,
        openVideoPlayer: fields?.primaryCta?.fields?.openVideoPlayer,
        id: fields?.primaryCta?.sys?.id,
        backgroundColor: fields?.primaryCta?.fields?.backgroundColor,
      },
      secondaryCta: {
        label: fields?.secondaryCta?.fields?.label,
        url: fields?.secondaryCta?.fields?.url,
        openInNewTab: fields?.secondaryCta?.fields?.openInNewTab,
        openVideoPlayer: fields?.secondaryCta?.fields?.openVideoPlayer,
        id: fields?.secondaryCta?.sys?.id,
        backgroundColor: fields?.secondaryCta?.fields?.backgroundColor,
      },
      // deprecated - replaced by primary and secondary cta
      ctaLeftText: fields?.ctaLeftText,
      ctaRightText: fields?.ctaRightText,
      ctaLeftUrl: fields?.ctaLeftUrl,
      ctaRightUrl: fields?.ctaRightUrl,
    }
  },

  enablePreview: async (module, preview = false) => {
    let id = module.sys.id
    const entry = await fetchGraphQL(
      `query {
        moduleContent2Blocks(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
                backgroundImage{
                  url
                }
                overlayType
                imageSize
                contentAboveTitle{
                    json
                }
                generalSectionEyebrow
                generalSectionTitle
                generalSectionSubtitle
                inset
                generalPaddingDisable
                imagePosition
                eyebrow
                headline
                bodyText{
                  json
                }

                primaryCta{
                  label
                  url
                  openInNewTab
                  openVideoPlayer
                  backgroundColor
                }
                secondaryCta{
                  label
                  url
                  openInNewTab
                  openVideoPlayer
                  backgroundColor
                }

                # deprecated - replaced by primary and secondary cta
                ctaLeftText
                ctaRightText
                ctaLeftUrl
                ctaRightUrl
      }
    }`,
      preview
    )
    return entry?.data?.moduleContent2Blocks
  },
}
export default moduleContent2Blocks
