const moduleHeroSection = {
  name: 'moduleHeroSection',

  transform: (data) => {
    const { fields } = data
    return {
      spaceBelowContent: fields?.spaceBelowContent,
      fullHeight: fields?.fullHeight,
      inset: fields?.inset,
      backgroundImage: fields?.backgroundImage?.fields?.file
        ? 'https:' + fields?.backgroundImage?.fields?.file.url
        : '',
      altTag: fields?.backgroundImage?.fields?.file
        ? fields?.backgroundImage?.fields?.description
        : '',
      overlayType: fields?.overlayType,
      eyebrow: fields?.eyebrow,
      spaceBelowTextContent: fields?.spaceBelowTextContent,
      useParallaxBackground: fields?.useParallaxBackground,
      headline: fields?.headline,
      bodyText: fields?.bodyText,
      modules: fields?.modules,
      heroType: fields?.heroType,
      showCta: fields?.showCta,
      menuEnable: fields?.menuEnable,
      firstOnPage: fields?.firstOnPage,

      ctaLeft: {
        label: fields?.ctaLeft?.fields?.label,
        url: fields?.ctaLeft?.fields?.url,
        openInNewTab: fields?.ctaLeft?.fields?.openInNewTab,
        openVideoPlayer: fields?.ctaLeft?.fields?.openVideoPlayer,
        id: fields?.ctaLeft?.sys?.id,
        backgroundColor: fields?.ctaLeft?.fields?.backgroundColor,
      },

      ctaRight: {
        label: fields?.ctaRight?.fields?.label,
        url: fields?.ctaRight?.fields?.url,
        openInNewTab: fields?.ctaRight?.fields?.openInNewTab,
        openVideoPlayer: fields?.ctaRight?.fields?.openVideoPlayer,
        id: fields?.ctaRight?.sys?.id,
        backgroundColor: fields?.ctaRight?.fields?.backgroundColor,
      },

      // deprecated - replaced by CTA modules
      ctaLeftText: fields?.ctaLeftText,
      ctaLeftUrl: fields?.ctaLeftUrl,
      ctaRightText: fields?.ctaRightText,
      ctaRightUrl: fields?.ctaRightUrl,
      isPLP: fields?.isPLP,
      productData: fields?.product,
    }
  },
}
export default moduleHeroSection
