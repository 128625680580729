import { fetchGraphQL } from '@/lib/contentful/api'

const moduleContent3Blocks = {
  name: 'moduleContent3Blocks',

  transform: (data, preview = false) => {
    const { fields } = data

    return {
      overlayType: fields.overlayType,
      eyebrow: fields.eyebrow,
      eyebrow2: fields.eyebrow2,
      eyebrow3: fields.eyebrow3,
      headline: fields.headline,
      headline2: fields.headline2,
      headline3: fields.headline3,
      bodyText: fields.bodyText,
      bodyText2: fields.bodyText2,
      bodyText3: fields.bodyText3,

      ctaLeftPrimary: {
        label: fields?.ctaLeftPrimary?.fields?.label,
        url: fields?.ctaLeftPrimary?.fields?.url,
        openInNewTab: fields?.ctaLeftPrimary?.fields?.openInNewTab,
        openVideoPlayer: fields?.ctaLeftPrimary?.fields?.openVideoPlayer,
        id: fields?.ctaLeftPrimary?.sys?.id,
        backgroundColor: fields?.ctaLeftPrimary?.fields?.backgroundColor,
      },
      ctaLeftSecondary: {
        label: fields?.ctaLeftSecondary?.fields?.label,
        url: fields?.ctaLeftSecondary?.fields?.url,
        openInNewTab: fields?.ctaLeftSecondary?.fields?.openInNewTab,
        openVideoPlayer: fields?.ctaLeftSecondary?.fields?.openVideoPlayer,
        id: fields?.ctaLeftSecondary?.sys?.id,
        backgroundColor: fields?.ctaLeftSecondary?.fields?.backgroundColor
      },
      ctaRightTop: {
        label: fields?.ctaRightTop?.fields?.label,
        url: fields?.ctaRightTop?.fields?.url,
        openInNewTab: fields?.ctaRightTop?.fields?.openInNewTab,
        openVideoPlayer: fields?.ctaRightTop?.fields?.openVideoPlayer,
        id: fields?.ctaRightTop?.sys?.id,
        backgroundColor: fields?.ctaRightTop?.fields?.backgroundColor
      },
      ctaRightBottom: {
        label: fields?.ctaRightBottom?.fields?.label,
        url: fields?.ctaRightBottom?.fields?.url,
        openInNewTab: fields?.ctaRightBottom?.fields?.openInNewTab,
        openVideoPlayer: fields?.ctaRightBottom?.fields?.openVideoPlayer,
        id: fields?.ctaRightBottom?.sys?.id,
        backgroundColor: fields?.ctaRightBottom?.fields?.backgroundColor
      },

      // deprecated - replaced by cta modules
      ctaLeftText: fields.ctaLeftText,
      ctaLeftText2: fields.ctaLeftText2,
      ctaLeftText3: fields.ctaLeftText3,
      ctaRightText: fields.ctaRightText,
      ctaLeftUrl: fields.ctaLeftUrl,
      ctaLeftUrl2: fields.ctaLeftUrl2,
      ctaLeftUrl3: fields.ctaLeftUrl3,
      ctaRightUrl: fields.ctaRightUrl,
      backgroundImage: fields.backgroundImage?.fields?.file?.url
        ? 'https:' + fields.backgroundImage.fields.file.url
        : '',
    }
  },

  enablePreview: async (module, preview = false) => {
    let id = module.sys.id
    const entry = await fetchGraphQL(
      `query {
        moduleContent3Blocks(id: "${id}",preview: ${preview ? 'true' : 'false'}) {
            overlayType
            backgroundImage{
              url
            }
            eyebrow
            headline
            bodyText{
              json
            }

            eyebrow2
            headline2
            bodyText2{
              json
            }

            eyebrow3
            headline3
            bodyText3{
                json
            }

            ctaLeftPrimary {
              label
              url
              openInNewTab
              openVideoPlayer
              backgroundColor
            }
            ctaLeftSecondary {
              label
              url
              openInNewTab
              openVideoPlayer
              backgroundColor
            }
            ctaRightTop {
              label
              url
              openInNewTab
              openVideoPlayer
              backgroundColor
            }
            ctaRightBottom {
              label
              url
              openInNewTab
              openVideoPlayer
              backgroundColor
            }
            
            # deprecated
            ctaLeftUrl3
            ctaLeftText3
            ctaLeftUrl2
            ctaLeftText2
            ctaLeftUrl
            ctaRightUrl
            ctaLeftText
            ctaRightText
        }
    }`,
      preview
    )
    return entry?.data?.moduleContent3Blocks
  },
}
export default moduleContent3Blocks
